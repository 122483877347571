<template>
  <v-container>
    <!-- BOC:[error] -->
    <ApiErrorDialog v-if="api.isError" :api="api" />
    <ApiErrorDialog v-if="leagueApi.isError" :api="leagueApi" />
    <!-- EOC -->
    <div class="tab-bar-card">
      <v-carousel
        :continuous="false"
        hide-delimiters
        height="auto"
        v-model="currentIndex"
        @change="changeLeague()"
      >
        <v-carousel-item v-for="(item, i) in items" :key="i">
          <div class="px-10" style="position: relative">
            <v-img
              max-width="200"
              :src="item.imageUrl"
              contain
              class="mx-auto d-flex align-center justify-center py-12 mt-5"
              @click="
                () => {
                  $router.push({
                    name: 'PageEventRankDetail',
                  });
                }
              "
            >
              <v-btn icon style="position: absolute; top: 0; right: 0">
                <v-icon large>mdi-information</v-icon>
              </v-btn>

              <div class="text-stroke-sm text-h5 mt-5">
                {{ item.name }}
              </div>
              <div class="text-stroke-sm text-h5">
                {{ item.tier }}
              </div>
            </v-img>
            <div class="reward-panel">
              <div>{{ $t("model.prop.rewards") }}:</div>
              <div class="d-flex" v-if="myRewards.length > 0">
                <!-- <Item
                  v-for="(myReward, i) in myRewards"
                  :key="i"
                  :imageUrl="JSON.parse(myReward.images).icon.url"
                  :isShowCount="true"
                  :count="myReward.quantity"
                  style="max-height: 70px; max-width: 50px;"
                  class="mx-1"
                /> -->
                <div
                  class="coin-box"
                  v-for="(myReward, i) in myRewards"
                  :key="i"
                >
                  <v-img
                    max-width="30"
                    :src="JSON.parse(myReward.images).icon.url"
                    class="mx-auto d-flex align-center justify-center coin-img"
                  >
                  </v-img>
                  +{{ myReward.quantity }}
                </div>
              </div>
            </div>
          </div>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-select
      v-if="auth.Player.userType == 'adult'"
      :items="lables"
      item-text="name"
      item-value="id"
      background-color="transparent"
      item-color="brown"
      v-model="filter"
      autofocus
      flat
      dense
      hide-details
      solo
      class="ma-3 custom-select"
      color="brown"
      @change="filterStudents()"
    ></v-select>
    <div class="list-panel" v-if="selected_data.length > 0">
      <div v-if="showAll">
        <div
          v-for="(student, i) in selected_data"
          :key="i"
          :class="
            auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'
          "
        >
          <div class="d-flex align-center px-2">
            {{ i + 1 }}
            <AbstractAvatar
              v-if="student.avatar"
              :avatar="student.avatar"
              :height="50"
              :width="50"
              class="ma-1"
            />
            <v-img
              v-else
              class="ma-1"
              max-height="50"
              max-width="50"
              :src="require('@/assets/entity/UnnamedCharacter1.png')"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>

            <div
              :class="
                auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
              "
              style="word-break: break-all"
            >
              {{
                (student.institutionId &&
                  auth.Institution &&
                  student.institutionId == auth.Institution.id) ||
                auth.Player.mochiId == student.mochiId
                  ? student.name
                  : student.mochiId
              }}
              <!-- {{ student.mochiId }} -->
            </div>
          </div>
          <div class="d-flex align-center me-2">
            {{ student.totalStar }}
            <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
              mdi-star</v-icon
            >
            <div
              class="px-2"
              :class="
                auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'
              "
            >
              {{ student.totalScore }}
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="topStudents.length > 0">
          <div
            v-for="(student, i) in topStudents"
            :key="i"
            :class="
              auth.Player.mochiId == student.mochiId
                ? 'selected-list-card'
                : 'list-card'
            "
          >
            <div class="d-flex align-center px-2">
              {{ i + 1 }}
              <AbstractAvatar
                v-if="student.avatar"
                :avatar="student.avatar"
                :height="50"
                :width="50"
                class="ma-1"
              />
              <v-img
                v-else
                class="ma-1"
                max-height="50"
                max-width="50"
                :src="require('@/assets/entity/UnnamedCharacter1.png')"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                :class="
                  auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
                "
                style="word-break: break-all"
              >
                {{
                  (student.institutionId &&
                    auth.Institution &&
                    student.institutionId == auth.Institution.id) ||
                  auth.Player.mochiId == student.mochiId
                    ? student.name
                    : student.mochiId
                }}
                <!-- {{ student.mochiId }} -->
              </div>
            </div>
            <div class="d-flex align-center me-2">
              {{ student.totalStar }}
              <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
                mdi-star</v-icon
              >
              <div
                class="px-2"
                :class="
                  auth.Player.mochiId == student.mochiId
                    ? 'selected-score'
                    : 'score'
                "
              >
                {{ student.totalScore }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-center brown--text text-h3 my-0"
            style="line-height: 15px"
          >
            • • •
          </div>
        </div>
        <div v-if="myMates.length > 0">
          <div
            v-for="(student, i) in myMates"
            :key="i"
            :class="
              auth.Player.mochiId == student.mochiId
                ? 'selected-list-card'
                : 'list-card'
            "
          >
            <div class="d-flex align-center px-2">
              {{ myMatecountStart + i }}
              <AbstractAvatar
                v-if="student.avatar"
                :avatar="student.avatar"
                :height="50"
                :width="50"
                class="ma-1"
              />
              <v-img
                v-else
                class="ma-1"
                max-height="50"
                max-width="50"
                :src="require('@/assets/entity/UnnamedCharacter1.png')"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>

              <div
                :class="
                  auth.Player.mochiId == student.mochiId ? 'selected-name' : ''
                "
                style="word-break: break-all"
              >
                {{
                  (student.institutionId &&
                    auth.Institution &&
                    student.institutionId == auth.Institution.id) ||
                  auth.Player.mochiId == student.mochiId
                    ? student.name
                    : student.mochiId
                }}
                <!-- {{ student.mochiId }} -->
              </div>
            </div>
            <div class="d-flex align-center me-2">
              {{ student.totalStar }}
              <v-icon color="yellow" class="ms-1 me-2 text-brd-sm">
                mdi-star</v-icon
              >
              <div
                class="px-2"
                :class="
                  auth.Player.mochiId == student.mochiId
                    ? 'selected-score'
                    : 'score'
                "
              >
                {{ student.totalScore }}
              </div>
            </div>
          </div>
          <div
            class="d-flex justify-center brown--text text-h3 my-0"
            style="line-height: 15px"
          >
            • • •
          </div>
        </div>
      </div>
    </div>
    <div class="list-panel" v-else>
      <div class="list-card">
        <div class="d-flex align-center px-2">
          {{ $t("string.noStudents") }}
        </div>
      </div>
    </div>
    <div class="text-center mt-5" v-if="showAllBtn">
      <v-btn small color="yellow" @click="showAll = !showAll">
        {{ showAll ? $t("string.show_less") : $t("string.show_all") }}
      </v-btn>
    </div>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import AvatarSkeleton from "@/components/skeleton/Avatar";
export default {
  components: {
    AbstractAvatar: () => ({
      component: import(
        /* webpackChunkName: "component-abstract-avatar-testing" */ "@/components/avatar/AbstractAvatar"
      ),
      loading: AvatarSkeleton,
      delay: 200,
    }),
    ApiErrorDialog: () =>
      import(
        /* webpackChunkName: "component-api-error-dialog" */ "@/components/ApiErrorDialog.vue"
      ),
    // Item: () =>
    //   import(
    //     /* webpackChunkName: "component-shop-item" */ "@/components/shop/Item.vue"
    //   ),
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    currentIndex: 0,
    items: [],
    myMatecountStart: 0,
    filter: 0,
    selected: -1,
    showAll: false,
    showAllBtn: false,
    topStudents: [],
    myMates: [],
    myRewards: [],
    students: [],
    selected_data: [],
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    leagueApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    lables: [],
  }),
  created() {
    this.api.url =
      this.$api.servers.event +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/event/league";

    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
    };

    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.items = resp;
      this.changeLeague();
    };
    this.api.method = "GET";
  },
  mounted() {
    if (this.auth.Classroom) {
      this.filter =this.auth.Classroom.schoolYear;
    } else {
      this.filter = this.auth.User.schoolYear;
    }

    this.lables = [
      // { id: 0, name: this.$t("string.all") },
      { id: 1, name: this.$t("string.year", { year: 1 }) },
      { id: 2, name: this.$t("string.year", { year: 2 }) },
      { id: 3, name: this.$t("string.year", { year: 3 }) },
      { id: 4, name: this.$t("string.year", { year: 4 }) },
      { id: 5, name: this.$t("string.year", { year: 5 }) },
      { id: 6, name: this.$t("string.year", { year: 6 }) },
    ];
    this.$api.fetch(this.api);
  },
  methods: {
    changeLeague() {
      this.leagueApi.url =
        this.$api.servers.event +
        "/api/v1/" +
        this.$_getLocale() +
        "/game/event/ranking/league";

      this.leagueApi.callbackReset = () => {
        this.leagueApi.isLoading = true;
        this.leagueApi.isError = false;
      };

      this.leagueApi.callbackError = (e) => {
        this.leagueApi.isLoading = false;
        this.leagueApi.isError = true;
        this.leagueApi.error = e;
      };
      this.leagueApi.callbackSuccess = (resp) => {
        this.leagueApi.isLoading = false;
        this.selected_data = resp;
        this.students = resp;
        this.showStudents();
      };
      this.leagueApi.params = {
        eventCode: this.$route.params.code,
        leagueId: this.items[this.currentIndex].id,
      };
      this.$api.fetch(this.leagueApi);
    },
    showStudents() {
      this.myMates = [];
      this.topStudents = [];
      if (this.selected_data.length <= 5) {
        this.showAll = true;
        this.showAllBtn = false;
        let me = this.selected_data.findIndex(
          (element) => this.auth.Player.mochiId == element.mochiId
        );

        me += 1;
        if (me > 0) {
          this.items[this.currentIndex].LeagueGroup.forEach((element) => {
            if (me >= element.start && me <= element.end) {
              this.myRewards = element.LeagueGroupReward;
            }
          });
        } else {
          this.myRewards =
            this.items[this.currentIndex].LeagueGroup[0].LeagueGroupReward;
        }
      } else {
        this.showAll = false;
        this.showAllBtn = true;

        let me = this.selected_data.findIndex(
          (element) => this.auth.Player.mochiId == element.mochiId
        );

        me += 1;
        if (me > 0) {
          this.items[this.currentIndex].LeagueGroup.forEach((element) => {
            if (me >= element.start && me <= element.end) {
              this.myRewards = element.LeagueGroupReward;
            }
          });
        } else {
          this.myRewards =
            this.items[this.currentIndex].LeagueGroup[0].LeagueGroupReward;
        }

        if (me > 5) {
          this.topStudents = this.selected_data.slice(0, 3);
          if (me == this.selected_data.length - 1) {
            this.myMates = this.selected_data.slice(-3);
            this.myMatecountStart = me - 2;
          } else {
            this.myMates = this.selected_data.slice(me - 1, me + 2);
            this.myMatecountStart = me;
          }
        } else {
          this.topStudents = this.selected_data.slice(0, 5);
        }
      }
    },
    filterStudents() {
      if (this.filter == 0) {
        this.selected_data = this.students;
      } else {
        this.selected_data = this.students.filter(
          (student) => student.schoolYear == this.filter
        );
      }
      this.showStudents();
    },
  },
};
</script>

    <style scoped>
.custom-select {
  border: 2px solid #b99c7a;
  border-radius: 0;
  background-color: #fff2e2;
}
.tab-bar-card {
  border-radius: 5px;
  border: #ccaf8b 4px solid;
  background-color: #e1c8aa;
  text-align: center;
  position: relative;
}

.text-stroke-sm {
  color: #ffdd1f;
  text-shadow: #000 3px 0px 0px, #000 2.83487px 0.981584px 0px,
    #000 2.35766px 1.85511px 0px, #000 1.62091px 2.52441px 0px,
    #000 0.705713px 2.91581px 0px, #000 -0.287171px 2.98622px 0px,
    #000 -1.24844px 2.72789px 0px, #000 -2.07227px 2.16926px 0px,
    #000 -2.66798px 1.37182px 0px, #000 -2.96998px 0.42336px 0px,
    #000 -2.94502px -0.571704px 0px, #000 -2.59586px -1.50383px 0px,
    #000 -1.96093px -2.27041px 0px, #000 -1.11013px -2.78704px 0px,
    #000 -0.137119px -2.99686px 0px, #000 0.850987px -2.87677px 0px,
    #000 1.74541px -2.43999px 0px, #000 2.44769px -1.73459px 0px,
    #000 2.88051px -0.838247px 0px;
}

.list-panel {
  margin-top: 20px;
  background-color: #b99c7a;
  padding: 5px 0;
}

.list-card {
  padding: 0 5px;
  margin: 10px;
  background-color: #fff2e2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #785b38;
}

.selected-list-card {
  padding: 0 5px;
  color: #785b38;
  margin: 10px;
  background-color: #ffc872;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reward-panel {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: small;
}

.coin-box {
  line-height: 12px;
  background-color: #fff;
  border: #292218 solid 2px;
  margin: 0 3px;
  font-size: x-small;
}

.score {
  background-color: #e0c8aa;
  width: 60px;
  text-align: right;
}
.selected-score {
  background-color: #fff2e2;
  width: 60px;
  text-align: right;
}

.text-brd-md {
  text-shadow: #8b5629 2px 0px 0px, #8b5629 1.75517px 0.958851px 0px,
    #8b5629 1.0806px 1.68294px 0px, #8b5629 0.141474px 1.99499px 0px,
    #8b5629 -0.832294px 1.81859px 0px, #8b5629 -1.60229px 1.19694px 0px,
    #8b5629 -1.97998px 0.28224px 0px, #8b5629 -1.87291px -0.701566px 0px,
    #8b5629 -1.30729px -1.5136px 0px, #8b5629 -0.421592px -1.95506px 0px,
    #8b5629 0.567324px -1.91785px 0px, #8b5629 1.41734px -1.41108px 0px,
    #8b5629 1.92034px -0.558831px 0px;
}

.text-brd-sm {
  text-shadow: #8b5629 1px 0px 0px, #8b5629 0.540302px 0.841471px 0px,
    #8b5629 -0.416147px 0.909297px 0px, #8b5629 -0.989992px 0.14112px 0px,
    #8b5629 -0.653644px -0.756802px 0px, #8b5629 0.283662px -0.958924px 0px,
    #8b5629 0.96017px -0.279415px 0px;
}
.selected-name {
  font-size: 20px;
  color: #fff;
  text-shadow: #8b5629 2px 0px 0px, #8b5629 1.75517px 0.958851px 0px,
    #8b5629 1.0806px 1.68294px 0px, #8b5629 0.141474px 1.99499px 0px,
    #8b5629 -0.832294px 1.81859px 0px, #8b5629 -1.60229px 1.19694px 0px,
    #8b5629 -1.97998px 0.28224px 0px, #8b5629 -1.87291px -0.701566px 0px,
    #8b5629 -1.30729px -1.5136px 0px, #8b5629 -0.421592px -1.95506px 0px,
    #8b5629 0.567324px -1.91785px 0px, #8b5629 1.41734px -1.41108px 0px,
    #8b5629 1.92034px -0.558831px 0px;
}

.coin-img {
  background-color: #6b583c;
}
</style>