var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',[(_vm.api.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.api}}):_vm._e(),(_vm.leagueApi.isError)?_c('ApiErrorDialog',{attrs:{"api":_vm.leagueApi}}):_vm._e(),_c('div',{staticClass:"tab-bar-card"},[_c('v-carousel',{attrs:{"continuous":false,"hide-delimiters":"","height":"auto"},on:{"change":function($event){return _vm.changeLeague()}},model:{value:(_vm.currentIndex),callback:function ($$v) {_vm.currentIndex=$$v},expression:"currentIndex"}},_vm._l((_vm.items),function(item,i){return _c('v-carousel-item',{key:i},[_c('div',{staticClass:"px-10",staticStyle:{"position":"relative"}},[_c('v-img',{staticClass:"mx-auto d-flex align-center justify-center py-12 mt-5",attrs:{"max-width":"200","src":item.imageUrl,"contain":""},on:{"click":() => {
                _vm.$router.push({
                  name: 'PageEventRankDetail',
                });
              }}},[_c('v-btn',{staticStyle:{"position":"absolute","top":"0","right":"0"},attrs:{"icon":""}},[_c('v-icon',{attrs:{"large":""}},[_vm._v("mdi-information")])],1),_c('div',{staticClass:"text-stroke-sm text-h5 mt-5"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"text-stroke-sm text-h5"},[_vm._v(" "+_vm._s(item.tier)+" ")])],1),_c('div',{staticClass:"reward-panel"},[_c('div',[_vm._v(_vm._s(_vm.$t("model.prop.rewards"))+":")]),(_vm.myRewards.length > 0)?_c('div',{staticClass:"d-flex"},_vm._l((_vm.myRewards),function(myReward,i){return _c('div',{key:i,staticClass:"coin-box"},[_c('v-img',{staticClass:"mx-auto d-flex align-center justify-center coin-img",attrs:{"max-width":"30","src":JSON.parse(myReward.images).icon.url}}),_vm._v(" +"+_vm._s(myReward.quantity)+" ")],1)}),0):_vm._e()])],1)])}),1)],1),(_vm.auth.Player.userType == 'adult')?_c('v-select',{staticClass:"ma-3 custom-select",attrs:{"items":_vm.lables,"item-text":"name","item-value":"id","background-color":"transparent","item-color":"brown","autofocus":"","flat":"","dense":"","hide-details":"","solo":"","color":"brown"},on:{"change":function($event){return _vm.filterStudents()}},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}):_vm._e(),(_vm.selected_data.length > 0)?_c('div',{staticClass:"list-panel"},[(_vm.showAll)?_c('div',_vm._l((_vm.selected_data),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
            ? 'selected-list-card'
            : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(i + 1)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : '',staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s((student.institutionId && _vm.auth.Institution && student.institutionId == _vm.auth.Institution.id) || _vm.auth.Player.mochiId == student.mochiId ? student.name : student.mochiId)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                ? 'selected-score'
                : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")])],1)])}),0):_c('div',[(_vm.topStudents.length > 0)?_c('div',[_vm._l((_vm.topStudents),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(i + 1)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : '',staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s((student.institutionId && _vm.auth.Institution && student.institutionId == _vm.auth.Institution.id) || _vm.auth.Player.mochiId == student.mochiId ? student.name : student.mochiId)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")])],1)])}),_c('div',{staticClass:"d-flex justify-center brown--text text-h3 my-0",staticStyle:{"line-height":"15px"}},[_vm._v(" • • • ")])],2):_vm._e(),(_vm.myMates.length > 0)?_c('div',[_vm._l((_vm.myMates),function(student,i){return _c('div',{key:i,class:_vm.auth.Player.mochiId == student.mochiId
              ? 'selected-list-card'
              : 'list-card'},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(_vm.myMatecountStart + i)+" "),(student.avatar)?_c('AbstractAvatar',{staticClass:"ma-1",attrs:{"avatar":student.avatar,"height":50,"width":50}}):_c('v-img',{staticClass:"ma-1",attrs:{"max-height":"50","max-width":"50","src":require('@/assets/entity/UnnamedCharacter1.png')},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey lighten-5"}})],1)]},proxy:true}],null,true)}),_c('div',{class:_vm.auth.Player.mochiId == student.mochiId ? 'selected-name' : '',staticStyle:{"word-break":"break-all"}},[_vm._v(" "+_vm._s((student.institutionId && _vm.auth.Institution && student.institutionId == _vm.auth.Institution.id) || _vm.auth.Player.mochiId == student.mochiId ? student.name : student.mochiId)+" ")])],1),_c('div',{staticClass:"d-flex align-center me-2"},[_vm._v(" "+_vm._s(student.totalStar)+" "),_c('v-icon',{staticClass:"ms-1 me-2 text-brd-sm",attrs:{"color":"yellow"}},[_vm._v(" mdi-star")]),_c('div',{staticClass:"px-2",class:_vm.auth.Player.mochiId == student.mochiId
                  ? 'selected-score'
                  : 'score'},[_vm._v(" "+_vm._s(student.totalScore)+" ")])],1)])}),_c('div',{staticClass:"d-flex justify-center brown--text text-h3 my-0",staticStyle:{"line-height":"15px"}},[_vm._v(" • • • ")])],2):_vm._e()])]):_c('div',{staticClass:"list-panel"},[_c('div',{staticClass:"list-card"},[_c('div',{staticClass:"d-flex align-center px-2"},[_vm._v(" "+_vm._s(_vm.$t("string.noStudents"))+" ")])])]),(_vm.showAllBtn)?_c('div',{staticClass:"text-center mt-5"},[_c('v-btn',{attrs:{"small":"","color":"yellow"},on:{"click":function($event){_vm.showAll = !_vm.showAll}}},[_vm._v(" "+_vm._s(_vm.showAll ? _vm.$t("string.show_less") : _vm.$t("string.show_all"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }